import React, { useState } from "react";
import { purchaseTokensPlusPurchaseTokensFree } from '../eth-setup/apis';
import "../Style/Mint.css";

function Mint() {
  const [qty, setQty] = useState(1);

  return (
    <div className='col-lg-6'>
      <div id='mint'>
        <div className='mint__container'>
          <img src='/images/GT-logo.gif' className='title__gif' />
          <div className='inputs'>
            <div>
              <input
                onChange={(e) => setQty(e.target.value)}
                onClick={(e) => setQty(e.target.value)}
                min={0}
                type='number'
                className='PhoneButton'
                placeholder='0'
              />
            </div>
            <div>
              <a className='PhoneButton' onClick={() => purchaseTokensPlusPurchaseTokensFree(() => {}, qty)}>
                Mint Your Gandalf
              </a>
            </div>
            <div>
              <a className='PhoneButton'>View On Opensea</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mint;
